<template>
  <form class="password-reset" @submit.prevent="onPasswordReset">
    <InputText
      v-model="form.emailOrPhone"
      :validate="v.emailOrPhone"
      :caption="_T('@Email or phone')"
      :custom-error="usernameInvalid"
    />
    <div class="password-reset__footer">
      <ButtonPrimary
        button-width="268px"
        type="submit"
        :disabled="passwordResetHandler.isHandled"
      >
        {{ _T("@Restore password") }}
      </ButtonPrimary>
      <div
        class="password-reset__footer-button"
        @click.prevent="changeComponent('signUp')"
      >
        {{ _T("@Registration") }}
      </div>
      <div
        class="password-reset__footer-button"
        @click.prevent="changeComponent('signIn')"
      >
        {{ _T("@Back") }}
      </div>
    </div>
  </form>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import InputText from "~/modules/shared/inputs/InputText.vue";
import {
  successfulModal,
  accountModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { signFormStore } from "~/modules/account/store/accountFormStore";
import { requiredValidate } from "~/utils/validators";
import forbidden from "~/config/forbidden.config";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";

const emits = defineEmits(["changeComponent"]);

const formStore = signFormStore();
const modalStore = useModalStore();

const usernameInvalid = ref(null);

const successfulData = {
  title: _T("@Password reset"),
  description: _T("@Password change has been sent to email"),
  isWithTimeout: true,
};

const form = reactive({
  emailOrPhone: "",
});

const rules = computed(() => ({
  emailOrPhone: {
    required: requiredValidate(),
  },
}));

const v = useVuelidate(rules, form);

function requestOptions() {
  return {};
}

const passwordResetHandler = useSingletonHandler(() =>
  useAPI("/account/password/reset/initiate", {
    method: "POST",
    body: {
      username: form.emailOrPhone,
    },
  }),
);

async function onPasswordReset() {
  usernameInvalid.value = null;

  if (!formStore.isFormsReady()) {
    return;
  }

  await passwordResetHandler
    .handle()
    .then(() => {
      document.documentElement.style.overflow = "auto";
      modalStore.toggleModal(accountModal);
      modalStore.toggleModal(successfulModal, successfulData);
    })
    .catch((error) => {
      if (forbidden.ACCOUNT_USERNAME_INVALID === error.data.code) {
        usernameInvalid.value = _T(
          "@The user with this data is not registered, please register",
        );
      }
    });
}

function changeComponent(component) {
  emits("changeComponent", component);
}

onMounted(() => {
  formStore.addForm({ v, requestOptions, id: "PasswordReset" });
});

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.password-reset {
  width: 100%;

  @include flex-container(column, center, center);
  gap: 24px;

  padding: 24px 16px;

  &__footer {
    width: 100%;

    @include flex-container(column, center, center);
    gap: 16px;
  }

  &__footer-button {
    @include font(16, 20);
    color: var(--color-primary-base);

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
