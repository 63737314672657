<template>
  <section v-if="isDesktop" class="widget-helper">
    <TransitionGroup name=" widget">
      <div
        v-if="!activeMainWidget"
        ref="mainIcon"
        class="widget-helper__main"
        @click="toggleMainWidget"
      >
        <div class="widget-helper__main-item first-icon">
          <img
            src="~~/assets/icons/widget-helper-icon.svg"
            alt="widget helper"
          />
        </div>
        <div class="widget-helper__main-item second-icon">
          <img
            class="widget-helper__main-item-icon"
            src="~~/assets/icons/widget-helper-icon.svg"
            alt="widget helper"
          />
          <p class="widget-helper__main-item-text">
            {{ _T("@Connect with us") }}
          </p>
        </div>
      </div>
    </TransitionGroup>
    <div class="widget-helper__close" :class="{ active: activeMainWidget }">
      <div class="widget-helper__close-item" @click="toggleMainWidget">
        <img
          src="~~/assets/icons/widget-helper-close-icon.svg"
          alt="widget close"
        />
      </div>

      <div
        v-for="(item, index) in allWidgets"
        :key="index"
        class="widget-helper__item"
        :class="[item.img, { show: activeMainWidget }]"
        @click="clickSelectedIcon(index, item.link)"
      >
        <div class="widget-helper__item-picture">
          <img :src="`/img/social-media/${item.img}.svg`" :alt="item.img" />
        </div>
      </div>
      <div
        class="widget-helper__item phone"
        :class="{ show: activeMainWidget }"
        @click="clickPhone"
      >
        <div class="widget-helper__item-picture">
          <img
            :src="`/img/social-media/${widgetPhone.img}.svg`"
            :alt="widgetPhone.img"
          />
        </div>
      </div>
    </div>

    <TransitionGroup name="widget-completed">
      <div v-if="completed" class="widget-helper__completed">
        <span></span></div
    ></TransitionGroup>
  </section>

  <WidgetHelperMob v-else />
</template>

<script setup>
import WidgetHelperMob from "~~/modules/widgetHelper/WidgetHelperMob.vue";
import { addScopedEventListener } from "~~/utils/eventListener";

const widgetPhone = ref({
  img: "phone",
  name: "@Back call",
  active: false,
});

const allWidgets = ref([
  {
    img: "facebook",
    name: "Facebook",
    link: "https://www.facebook.com/logicpower.official/",
    active: false,
  },
  {
    img: "instagram",
    name: "Instagram",
    link: "https://www.instagram.com/logicpower_official/",
    active: false,
  },
  {
    img: "viber",
    name: "Viber",
    link: "viber://pa?chatURI=lpofficial",
    active: false,
  },

  {
    img: "telegram",
    name: "Telegram",
    link: "https://t.me/LogicPowerBot",
    active: false,
  },
]);

const isDesktop = ref(null);
const mainIcon = ref(null);
const activeMainWidget = ref(false);
const completed = ref(false);

function toggleMainWidget() {
  activeMainWidget.value = !activeMainWidget.value;
}

function clickPhone() {
  activeMainWidget.value = !activeMainWidget.value;
  ringostatAPI.openCallbackForm();
}

function clickSelectedIcon(index, url) {
  allWidgets.value[index].active = true;
  window.open(url, "_blank");

  setTimeout(() => {
    toggleMainWidget();
  }, 400);

  setTimeout(() => {
    mainIcon.value.style.opacity = "0";
    completed.value = true;
    allWidgets.value[index].active = false;
  }, 1000);

  setTimeout(() => {
    mainIcon.value.style.opacity = "1";
    completed.value = false;
  }, 2000);
}

function getWindowSize() {
  window.innerWidth > 680
    ? (isDesktop.value = true)
    : (isDesktop.value = false);
}

onMounted(() => {
  getWindowSize();
  addScopedEventListener(window, "resize", getWindowSize);
});

onUpdated(() => {
  getWindowSize();
});
</script>

<style lang="scss" scoped>
.widget-helper {
  position: fixed;
  z-index: 1000;
  bottom: 60px;
  right: 95px;

  width: 68px;
  height: 68px;
  @include flex-container(column, center, center);

  &__main {
    cursor: pointer;
    position: relative;
    z-index: 4;
    width: 68px;
    height: 68px;
    background-color: var(--color-primary-base);
    border-radius: 50%;
    overflow: hidden;
    animation: shadow 2.5s ease-in-out infinite 0.8s;
    transition: background-color 0.4s ease-in-out;

    &:hover {
      background-color: var(--color-primary-dark);
    }

    &.active {
      transition: opacity 0.4s ease-in-out;
      opacity: 0;
    }
  }

  &__main-item {
    width: 68px;
    height: 68px;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;

    @include flex-container(column, center, center);
    transition: all 0.4s ease-in-out;

    &.first-icon {
      transform: translateX(0);
      animation: firstStep 5s infinite linear 0.8s;
    }

    &.second-icon {
      transform: translateX(100%);
      animation: secondStep 5s infinite linear 0.8s;
    }
  }

  &__main-item-icon {
    width: 18px;
    height: 18px;
  }

  &__main-item-text {
    padding: 2px 8px 0;
    text-align: center;
    @include font(10, 12, 600);
    color: white;
  }

  &__close {
    position: absolute;
    z-index: 2;
  }

  &__close-title {
    @include font(18, 25, 500);
  }

  &__close-item {
    cursor: pointer;
    position: relative;
    z-index: 3;
    padding: 4px;
    background-color: #f9b590;
    border-radius: 50%;
    transition: all 0.4s ease-in-out;

    &:hover {
      background-color: var(--color-primary-dark);
    }
  }

  &__item {
    position: absolute;
    z-index: 2;
    top: -8px;
    left: -8px;

    cursor: pointer;
    width: 40px;
    height: 40px;
    pointer-events: none;

    background-color: var(--color-primary-base);
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    @include flex-container(column, center, center);

    &:hover.show {
      transition-delay: 0s;
      animation: hoverIcon 1.5s ease-in-out infinite;
    }
    &.show {
      pointer-events: all;
    }

    &.active {
      transition-delay: 0s;
      animation: shadowSelected 0.3s ease-in-out forwards;
    }

    &.phone {
      transition: all 0.2s ease-in-out 0.1s;

      &.show {
        transform: translate(-52px, 52px) rotate(720deg);
      }
    }
    &.facebook {
      transition: all 0.2s ease-in-out 0.2s;
      &.show {
        transform: translate(-72px) rotate(720deg);
      }
    }
    &.instagram {
      transition: all 0.2s ease-in-out 0.3s;
      &.show {
        transform: translate(-52px, -52px) rotate(720deg);
      }
    }
    &.viber {
      transition: all 0.2s ease-in-out 0.4s;
      &.show {
        transform: translate(0, -72px) rotate(720deg);
      }
    }
    &.telegram {
      transition: all 0.2s ease-in-out 0.5s;
      &.show {
        transform: translate(52px, -52px) rotate(720deg);
      }
    }
  }

  &__item-picture {
    position: relative;
    width: 40px;
    height: 40px;

    background-color: var(--color-primary-base);
    border-radius: 50%;

    @include flex-container(row, center, center);

    & img {
      width: 24px;
      height: 24px;
    }
  }

  &__item-text {
    color: black;
    @include font(16, 19, 500);
    letter-spacing: -0.32px;
    transition: all 0.4s ease-in-out;
  }

  &__completed {
    position: absolute;
    z-index: 25;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
    background-color: white;
    border-radius: 50px;
    box-shadow: 0 0 1px 2px var(--color-primary-base);

    & span {
      position: absolute;
      z-index: 30;
      top: 40px;
      left: 17px;

      width: 20px;
      height: 6px;
      background-color: var(--color-primary-base);
      border-radius: 2px;

      transform: rotate(45deg);

      &::after {
        content: "";
        position: absolute;
        z-index: 30;

        top: -16px;
        left: -8px;

        width: 40px;
        height: 6px;
        background-color: var(--color-primary-base);
        border-radius: 2px;

        transform: rotate(65deg);
      }
    }
  }
}

.widget-leave-active,
.widget-enter-active {
  transition: all 0.8s linear;
}

.widget-enter-from {
  transition: all 0.8s linear;
  opacity: 0;
  transform: rotate(0) scale(0.5);
}

.widget-enter-to {
  transition: all 0.8s linear;
  opacity: 1;
  transform: rotate(1440deg) scale(1);
}

.widget-leave-to {
  transition: all 0.8s linear;
  transform: rotate(1440deg) scale(0.5);
  opacity: 0;
}

.widget-completed-leave-active,
.widget-completed-enter-active {
  transition: all 0.4s linear;
}
.widget-completed-enter-from {
  transform: rotate(-720deg) scale(0.1);
}

.widget-completed-leave-to {
  transform: rotate(-720deg) scale(0.1);
}

@keyframes firstStep {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-120%);
    opacity: 0;
  }
  30% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    opacity: 1;
    transform: translateX(100%);
  }
  60% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes secondStep {
  0% {
    opacity: 1;
    transform: translateX(100%);
  }
  10% {
    opacity: 1;
    transform: translateX(0%);
  }
  50% {
    opacity: 1;
    transform: translateX(0%);
  }
  60% {
    opacity: 0;
    transform: translateX(-120%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes shadow {
  0% {
    box-shadow: 0 0 2px rgba(242, 106, 33);
  }

  50% {
    box-shadow:
      0 0 1px 8px rgba(242, 106, 33, 0.4),
      0 0 1px 18px rgba(242, 106, 33, 0.3),
      0 0 1px 36px rgba(242, 106, 33, 0.15);
  }
  100% {
    box-shadow: 0 0 2px rgba(242, 106, 33);
  }
}

@keyframes shadowSelected {
  0% {
    box-shadow: 0 0 2px rgba(242, 106, 33);
  }

  50% {
    box-shadow:
      0 0 1px 8px rgba(242, 106, 33, 0.4),
      0 0 1px 18px rgba(242, 106, 33, 0.3),
      0 0 1px 36px rgba(242, 106, 33, 0.15);
    background-color: black;
  }
  100% {
    box-shadow: 0 0 2px rgba(242, 106, 33);
    background-color: black;
  }
}

@keyframes hoverIcon {
  0% {
    box-shadow: 0 0 2px rgba(242, 106, 33);
  }

  50% {
    box-shadow:
      0 0 1px 2px rgba(242, 106, 33, 0.4),
      0 0 1px 6px rgba(242, 106, 33, 0.3),
      0 0 1px 12px rgba(242, 106, 33, 0.15);
    background-color: var(--color-primary-dark);
  }
  100% {
    box-shadow: 0 0 2px rgba(242, 106, 33);
  }
}
</style>
