<template>
  <div
    ref="setNewPassword"
    class="modal"
    tabindex="0"
    @click.stop="closeModal"
    @keydown.esc="closeModal"
  >
    <div class="modal__wrapper" @click.stop>
      <div class="set-password">
        <div class="set-password__header">
          <p class="set-password__modal-name">
            {{ _T("@Change Password") }}
          </p>
          <button class="set-password__close" @click.stop.prevent="closeModal">
            <img src="~~/assets/icons/cross.svg" alt="close button" />
          </button>
        </div>
      </div>
      <form class="set-password__body" @submit.prevent="onSetPassword">
        <Password
          v-model="form.password"
          :validate="v.password"
          :caption="_T('@New Password')"
          autocomplete-type="'new-password'"
        />
        <Password
          v-model="form.confirmedPassword"
          :validate="v.confirmedPassword"
          :caption="_T('@Password confirmation')"
          autocomplete-type="'new-password'"
        />
        <ButtonPrimary
          button-width="268px"
          type="submit"
          :disabled="setPasswordHandler.isHandled"
        >
          {{ _T("@Will confirm") }}
        </ButtonPrimary>
      </form>
    </div>
  </div>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import Password from "~/modules/shared/inputs/Password.vue";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import {
  successfulModal,
  unsuccessfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import {
  minLengthValidate,
  regExpValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { useUserStore } from "~/store/userStore";
import { signFormStore } from "~/modules/account/store/accountFormStore";
import forbidden from "~/config/forbidden.config";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";

const emits = defineEmits(["close"]);

const formStore = signFormStore();
const modalStore = useModalStore();
const userStore = useUserStore();

const setNewPassword = ref(null);

const successfulData = {
  title: _T("@Change Password"),
  description: _T("@Password changed successfully"),
  isWithTimeout: true,
};

const unsuccessfulData = {
  title: _T("@Change Password"),
  description: _T("@Password changed unsuccessful"),
  isWithTimeout: false,
};

const form = reactive({
  password: "",
  confirmedPassword: "",
});

const rules = computed(() => ({
  password: {
    required: requiredValidate(),
    minLength: minLengthValidate("@Minimum password length", 6),
    validatePassword: regExpValidate(
      "@The password must contain at least one number",
      /\d/,
    ),
  },
  confirmedPassword: {
    required: requiredValidate(),
    sameAs: sameAsValidate("@Password mismatch", form.password),
  },
}));

const v = useVuelidate(rules, form);

function requestOptions() {
  return {};
}

const setPasswordHandler = useSingletonHandler(() =>
  useAPI(
    "/account/password/reset/complete",
    {
      method: "POST",
      body: {
        token: useRoute().query.recovery,
        newPassword: form.password,
      },
    },
    // { isAuthorized: false },
  ),
);

async function onSetPassword() {
  if (formStore.isFormsReady()) {
    try {
      await setPasswordHandler.handle();

      userStore.fetch();

      closeModal();
      modalStore.toggleModal(successfulModal, successfulData);
    } catch (error) {
      if (forbidden.ACCOUNT_PASSWORD_RESET_INVALID_TOKEN === error.data.code) {
        closeModal();
        modalStore.toggleModal(unsuccessfulModal, unsuccessfulData);
      }
    }
  }
}

function closeModal() {
  emits("close");
  document.documentElement.style.overflow = "auto";
}

onMounted(() => {
  formStore.addForm({ v, requestOptions, id: "setNewPassword" });
  setNewPassword.value.focus();
});

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss">
.set-password {
  border-radius: 8px;

  &__header {
    @include flex-container(row, space-between, center);

    border-bottom: 1px solid #d1d1d1;

    padding: 16px;
    gap: 16px;
  }

  &__modal-name {
    @include font(20, 28);
    letter-spacing: 0.02em;
    color: #2b2b2b;
  }

  &__close {
    @include fixedHW(32px, 32px);

    @include flex-container(row, center, center);

    & img {
      @include fixedHW(14px, 14px);
    }
  }

  &__body {
    @include flex-container(column, center, center);
    gap: 16px;

    padding: 24px 16px;
  }
}
</style>
