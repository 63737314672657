import { default as HomeplgfZwwLwJMeta } from "/usr/src/nuxt-app/modules/home/components/Home.vue?macro=true";
import { default as CatalogvCSMG2yPMdMeta } from "/usr/src/nuxt-app/modules/catalog/components/Catalog.vue?macro=true";
import { default as indexUzbQwBF1yxMeta } from "/usr/src/nuxt-app/modules/products/pages/index.vue?macro=true";
import { default as index7lalHtr8ScMeta } from "/usr/src/nuxt-app/modules/static/warranty/index.vue?macro=true";
import { default as SummarybCDA1t7xu6Meta } from "/usr/src/nuxt-app/modules/product/ui/pages/Summary?macro=true";
import { default as SpecificationscGnz3pGUDMMeta } from "/usr/src/nuxt-app/modules/product/ui/pages/Specifications?macro=true";
import { default as ReviewsLp9cAgEaKBMeta } from "/usr/src/nuxt-app/modules/product/ui/pages/Reviews?macro=true";
import { default as Videoss4tCWMHOArMeta } from "/usr/src/nuxt-app/modules/product/ui/pages/Videos?macro=true";
import { default as Downloadsi2JmvjrbGmMeta } from "/usr/src/nuxt-app/modules/product/ui/pages/Downloads?macro=true";
import { default as indexQWkH1FcnhQMeta } from "/usr/src/nuxt-app/modules/product/ui/layouts/index.vue?macro=true";
import { default as index3JH41m7qH7Meta } from "/usr/src/nuxt-app/modules/static/contacts/index.vue?macro=true";
import { default as index5otAliN6NBMeta } from "/usr/src/nuxt-app/modules/static/cooperation/index.vue?macro=true";
import { default as index8toHTALCewMeta } from "/usr/src/nuxt-app/modules/static/privacyPolicy/index.vue?macro=true";
import { default as indexuXDRZXUVJwMeta } from "/usr/src/nuxt-app/modules/static/publicOffer/index.vue?macro=true";
import { default as indexOTZMymEf8CMeta } from "/usr/src/nuxt-app/modules/blog/pages/catalog/index.vue?macro=true";
import { default as index39qV6e4bUTMeta } from "/usr/src/nuxt-app/modules/checkout/components/index.vue?macro=true";
import { default as Calculator5LYrwfynajMeta } from "/usr/src/nuxt-app/modules/calculator/components/Calculator.vue?macro=true";
import { default as indexp24HkqoUVzMeta } from "/usr/src/nuxt-app/modules/blog/pages/post/index.vue?macro=true";
import { default as Comparisondzs4Z6XSdgMeta } from "/usr/src/nuxt-app/modules/comparison/components/Comparison.vue?macro=true";
import { default as indexvPvn3gXZUCMeta } from "/usr/src/nuxt-app/modules/static/delivery/index.vue?macro=true";
import { default as indexohyOKhsw77Meta } from "/usr/src/nuxt-app/modules/order/components/index.vue?macro=true";
import { default as indexbzUlwcxTjCMeta } from "/usr/src/nuxt-app/modules/static/landing/solarPowerPlant/index.vue?macro=true";
import { default as ProfilePcI75Uc1qfMeta } from "/usr/src/nuxt-app/modules/account/components/tabs/Profile.vue?macro=true";
import { default as OrdersGqgpmBuz1QMeta } from "/usr/src/nuxt-app/modules/account/components/tabs/Orders.vue?macro=true";
import { default as FavoritesObqtuYUkVwMeta } from "/usr/src/nuxt-app/modules/account/components/tabs/Favorites.vue?macro=true";
import { default as indexoNLrLOrvSlMeta } from "/usr/src/nuxt-app/modules/account/components/index.vue?macro=true";
import { default as index8ENwf69fp9Meta } from "/usr/src/nuxt-app/modules/lookupDevice/components/index.vue?macro=true";
import { default as indexvBYfwU18aNMeta } from "/usr/src/nuxt-app/modules/static/application/index.vue?macro=true";
import { default as indexWXP1WKu7iGMeta } from "/usr/src/nuxt-app/modules/blackFriday/index.vue?macro=true";
import { default as indexbmqbKMPIl0Meta } from "/usr/src/nuxt-app/modules/static/landing/autonomic/index.vue?macro=true";
import { default as indexhL90J2nn6yMeta } from "/usr/src/nuxt-app/modules/installationWork/pages/catalog/index.vue?macro=true";
import { default as indexcPqg33AOicMeta } from "/usr/src/nuxt-app/modules/installationWork/pages/article/index.vue?macro=true";
import { default as indextJiflr0ffZMeta } from "/usr/src/nuxt-app/modules/static/reviewsPolicy/index.vue?macro=true";
import { default as indexlhxQnMtihPMeta } from "/usr/src/nuxt-app/modules/static/loyaltyPolicy/index.vue?macro=true";
export default [
  {
    name: HomeplgfZwwLwJMeta?.name ?? "ua_home",
    path: HomeplgfZwwLwJMeta?.path ?? "/",
    meta: HomeplgfZwwLwJMeta || {},
    alias: HomeplgfZwwLwJMeta?.alias || [],
    redirect: HomeplgfZwwLwJMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/home/components/Home.vue").then(m => m.default || m)
  },
  {
    name: HomeplgfZwwLwJMeta?.name ?? "ru_home",
    path: HomeplgfZwwLwJMeta?.path ?? "/ru",
    meta: HomeplgfZwwLwJMeta || {},
    alias: HomeplgfZwwLwJMeta?.alias || [],
    redirect: HomeplgfZwwLwJMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/home/components/Home.vue").then(m => m.default || m)
  },
  {
    name: CatalogvCSMG2yPMdMeta?.name ?? "ru_catalog",
    path: CatalogvCSMG2yPMdMeta?.path ?? "/catalog",
    meta: CatalogvCSMG2yPMdMeta || {},
    alias: CatalogvCSMG2yPMdMeta?.alias || [],
    redirect: CatalogvCSMG2yPMdMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/catalog/components/Catalog.vue").then(m => m.default || m)
  },
  {
    name: CatalogvCSMG2yPMdMeta?.name ?? "ua_catalog",
    path: CatalogvCSMG2yPMdMeta?.path ?? "/ua/catalog",
    meta: CatalogvCSMG2yPMdMeta || {},
    alias: CatalogvCSMG2yPMdMeta?.alias || [],
    redirect: CatalogvCSMG2yPMdMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/catalog/components/Catalog.vue").then(m => m.default || m)
  },
  {
    name: indexUzbQwBF1yxMeta?.name ?? "ru_category",
    path: indexUzbQwBF1yxMeta?.path ?? "/:categorySlug",
    meta: indexUzbQwBF1yxMeta || {},
    alias: indexUzbQwBF1yxMeta?.alias || [],
    redirect: indexUzbQwBF1yxMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/products/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexUzbQwBF1yxMeta?.name ?? "ua_category",
    path: indexUzbQwBF1yxMeta?.path ?? "/ua/:categorySlug",
    meta: indexUzbQwBF1yxMeta || {},
    alias: indexUzbQwBF1yxMeta?.alias || [],
    redirect: indexUzbQwBF1yxMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/products/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7lalHtr8ScMeta?.name ?? "ru_warranty",
    path: index7lalHtr8ScMeta?.path ?? "/warranty",
    meta: index7lalHtr8ScMeta || {},
    alias: index7lalHtr8ScMeta?.alias || [],
    redirect: index7lalHtr8ScMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/warranty/index.vue").then(m => m.default || m)
  },
  {
    name: index7lalHtr8ScMeta?.name ?? "ua_warranty",
    path: index7lalHtr8ScMeta?.path ?? "/ua/warranty",
    meta: index7lalHtr8ScMeta || {},
    alias: index7lalHtr8ScMeta?.alias || [],
    redirect: index7lalHtr8ScMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/warranty/index.vue").then(m => m.default || m)
  },
  {
    name: indexQWkH1FcnhQMeta?.name ?? "ru_product",
    path: indexQWkH1FcnhQMeta?.path ?? "/:categorySlug?/:productSlug?",
    meta: indexQWkH1FcnhQMeta || {},
    alias: indexQWkH1FcnhQMeta?.alias || [],
    redirect: indexQWkH1FcnhQMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/layouts/index.vue").then(m => m.default || m),
    children: [
  {
    name: SummarybCDA1t7xu6Meta?.name ?? "ru_product_summary",
    path: SummarybCDA1t7xu6Meta?.path ?? "",
    meta: SummarybCDA1t7xu6Meta || {},
    alias: SummarybCDA1t7xu6Meta?.alias || [],
    redirect: SummarybCDA1t7xu6Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Summary").then(m => m.default || m)
  },
  {
    name: SpecificationscGnz3pGUDMMeta?.name ?? "ru_product_specifications",
    path: SpecificationscGnz3pGUDMMeta?.path ?? "specifications",
    meta: SpecificationscGnz3pGUDMMeta || {},
    alias: SpecificationscGnz3pGUDMMeta?.alias || [],
    redirect: SpecificationscGnz3pGUDMMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Specifications").then(m => m.default || m)
  },
  {
    name: ReviewsLp9cAgEaKBMeta?.name ?? "ru_product_reviews",
    path: ReviewsLp9cAgEaKBMeta?.path ?? "reviews",
    meta: ReviewsLp9cAgEaKBMeta || {},
    alias: ReviewsLp9cAgEaKBMeta?.alias || [],
    redirect: ReviewsLp9cAgEaKBMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Reviews").then(m => m.default || m)
  },
  {
    name: Videoss4tCWMHOArMeta?.name ?? "ru_product_videos",
    path: Videoss4tCWMHOArMeta?.path ?? "videos",
    meta: Videoss4tCWMHOArMeta || {},
    alias: Videoss4tCWMHOArMeta?.alias || [],
    redirect: Videoss4tCWMHOArMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Videos").then(m => m.default || m)
  },
  {
    name: Downloadsi2JmvjrbGmMeta?.name ?? "ru_product_downloads",
    path: Downloadsi2JmvjrbGmMeta?.path ?? "downloads",
    meta: Downloadsi2JmvjrbGmMeta || {},
    alias: Downloadsi2JmvjrbGmMeta?.alias || [],
    redirect: Downloadsi2JmvjrbGmMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Downloads").then(m => m.default || m)
  }
]
  },
  {
    name: indexQWkH1FcnhQMeta?.name ?? "ua_product",
    path: indexQWkH1FcnhQMeta?.path ?? "/ua/:categorySlug?/:productSlug?",
    meta: indexQWkH1FcnhQMeta || {},
    alias: indexQWkH1FcnhQMeta?.alias || [],
    redirect: indexQWkH1FcnhQMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/layouts/index.vue").then(m => m.default || m),
    children: [
  {
    name: SummarybCDA1t7xu6Meta?.name ?? "ua_product_summary",
    path: SummarybCDA1t7xu6Meta?.path ?? "",
    meta: SummarybCDA1t7xu6Meta || {},
    alias: SummarybCDA1t7xu6Meta?.alias || [],
    redirect: SummarybCDA1t7xu6Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Summary").then(m => m.default || m)
  },
  {
    name: SpecificationscGnz3pGUDMMeta?.name ?? "ua_product_specifications",
    path: SpecificationscGnz3pGUDMMeta?.path ?? "specifications",
    meta: SpecificationscGnz3pGUDMMeta || {},
    alias: SpecificationscGnz3pGUDMMeta?.alias || [],
    redirect: SpecificationscGnz3pGUDMMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Specifications").then(m => m.default || m)
  },
  {
    name: ReviewsLp9cAgEaKBMeta?.name ?? "ua_product_reviews",
    path: ReviewsLp9cAgEaKBMeta?.path ?? "reviews",
    meta: ReviewsLp9cAgEaKBMeta || {},
    alias: ReviewsLp9cAgEaKBMeta?.alias || [],
    redirect: ReviewsLp9cAgEaKBMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Reviews").then(m => m.default || m)
  },
  {
    name: Videoss4tCWMHOArMeta?.name ?? "ua_product_videos",
    path: Videoss4tCWMHOArMeta?.path ?? "videos",
    meta: Videoss4tCWMHOArMeta || {},
    alias: Videoss4tCWMHOArMeta?.alias || [],
    redirect: Videoss4tCWMHOArMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Videos").then(m => m.default || m)
  },
  {
    name: Downloadsi2JmvjrbGmMeta?.name ?? "ua_product_downloads",
    path: Downloadsi2JmvjrbGmMeta?.path ?? "downloads",
    meta: Downloadsi2JmvjrbGmMeta || {},
    alias: Downloadsi2JmvjrbGmMeta?.alias || [],
    redirect: Downloadsi2JmvjrbGmMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Downloads").then(m => m.default || m)
  }
]
  },
  {
    name: index3JH41m7qH7Meta?.name ?? "ru_contacts",
    path: index3JH41m7qH7Meta?.path ?? "/contacts",
    meta: index3JH41m7qH7Meta || {},
    alias: index3JH41m7qH7Meta?.alias || [],
    redirect: index3JH41m7qH7Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: index3JH41m7qH7Meta?.name ?? "ua_contacts",
    path: index3JH41m7qH7Meta?.path ?? "/ua/contacts",
    meta: index3JH41m7qH7Meta || {},
    alias: index3JH41m7qH7Meta?.alias || [],
    redirect: index3JH41m7qH7Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: index5otAliN6NBMeta?.name ?? "ru_cooperation",
    path: index5otAliN6NBMeta?.path ?? "/cooperation",
    meta: index5otAliN6NBMeta || {},
    alias: index5otAliN6NBMeta?.alias || [],
    redirect: index5otAliN6NBMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/cooperation/index.vue").then(m => m.default || m)
  },
  {
    name: index5otAliN6NBMeta?.name ?? "ua_cooperation",
    path: index5otAliN6NBMeta?.path ?? "/ua/cooperation",
    meta: index5otAliN6NBMeta || {},
    alias: index5otAliN6NBMeta?.alias || [],
    redirect: index5otAliN6NBMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/cooperation/index.vue").then(m => m.default || m)
  },
  {
    name: index8toHTALCewMeta?.name ?? "ru_privacy-policy",
    path: index8toHTALCewMeta?.path ?? "/privacy-policy",
    meta: index8toHTALCewMeta || {},
    alias: index8toHTALCewMeta?.alias || [],
    redirect: index8toHTALCewMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/privacyPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: index8toHTALCewMeta?.name ?? "ua_privacy-policy",
    path: index8toHTALCewMeta?.path ?? "/ua/privacy-policy",
    meta: index8toHTALCewMeta || {},
    alias: index8toHTALCewMeta?.alias || [],
    redirect: index8toHTALCewMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/privacyPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indexuXDRZXUVJwMeta?.name ?? "ru_public-offer",
    path: indexuXDRZXUVJwMeta?.path ?? "/public-offer",
    meta: indexuXDRZXUVJwMeta || {},
    alias: indexuXDRZXUVJwMeta?.alias || [],
    redirect: indexuXDRZXUVJwMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/publicOffer/index.vue").then(m => m.default || m)
  },
  {
    name: indexuXDRZXUVJwMeta?.name ?? "ua_public-offer",
    path: indexuXDRZXUVJwMeta?.path ?? "/ua/public-offer",
    meta: indexuXDRZXUVJwMeta || {},
    alias: indexuXDRZXUVJwMeta?.alias || [],
    redirect: indexuXDRZXUVJwMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/publicOffer/index.vue").then(m => m.default || m)
  },
  {
    name: indexOTZMymEf8CMeta?.name ?? "ru_blog",
    path: indexOTZMymEf8CMeta?.path ?? "/blog",
    meta: indexOTZMymEf8CMeta || {},
    alias: indexOTZMymEf8CMeta?.alias || [],
    redirect: indexOTZMymEf8CMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/blog/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexOTZMymEf8CMeta?.name ?? "ua_blog",
    path: indexOTZMymEf8CMeta?.path ?? "/ua/blog",
    meta: indexOTZMymEf8CMeta || {},
    alias: indexOTZMymEf8CMeta?.alias || [],
    redirect: indexOTZMymEf8CMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/blog/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: index39qV6e4bUTMeta?.name ?? "ru_checkout",
    path: index39qV6e4bUTMeta?.path ?? "/checkout",
    meta: index39qV6e4bUTMeta || {},
    alias: index39qV6e4bUTMeta?.alias || [],
    redirect: index39qV6e4bUTMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/checkout/components/index.vue").then(m => m.default || m)
  },
  {
    name: index39qV6e4bUTMeta?.name ?? "ua_checkout",
    path: index39qV6e4bUTMeta?.path ?? "/ua/checkout",
    meta: index39qV6e4bUTMeta || {},
    alias: index39qV6e4bUTMeta?.alias || [],
    redirect: index39qV6e4bUTMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/checkout/components/index.vue").then(m => m.default || m)
  },
  {
    name: Calculator5LYrwfynajMeta?.name ?? "ru_calculator",
    path: Calculator5LYrwfynajMeta?.path ?? "/calculator",
    meta: Calculator5LYrwfynajMeta || {},
    alias: Calculator5LYrwfynajMeta?.alias || [],
    redirect: Calculator5LYrwfynajMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/calculator/components/Calculator.vue").then(m => m.default || m)
  },
  {
    name: Calculator5LYrwfynajMeta?.name ?? "ua_calculator",
    path: Calculator5LYrwfynajMeta?.path ?? "/ua/calculator",
    meta: Calculator5LYrwfynajMeta || {},
    alias: Calculator5LYrwfynajMeta?.alias || [],
    redirect: Calculator5LYrwfynajMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/calculator/components/Calculator.vue").then(m => m.default || m)
  },
  {
    name: indexp24HkqoUVzMeta?.name ?? "ru_blog_post",
    path: indexp24HkqoUVzMeta?.path ?? "/blog/:blogSlug",
    meta: indexp24HkqoUVzMeta || {},
    alias: indexp24HkqoUVzMeta?.alias || [],
    redirect: indexp24HkqoUVzMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/blog/pages/post/index.vue").then(m => m.default || m)
  },
  {
    name: indexp24HkqoUVzMeta?.name ?? "ua_blog_post",
    path: indexp24HkqoUVzMeta?.path ?? "/ua/blog/:blogSlug",
    meta: indexp24HkqoUVzMeta || {},
    alias: indexp24HkqoUVzMeta?.alias || [],
    redirect: indexp24HkqoUVzMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/blog/pages/post/index.vue").then(m => m.default || m)
  },
  {
    name: Comparisondzs4Z6XSdgMeta?.name ?? "ru_comparison",
    path: Comparisondzs4Z6XSdgMeta?.path ?? "/comparison",
    meta: Comparisondzs4Z6XSdgMeta || {},
    alias: Comparisondzs4Z6XSdgMeta?.alias || [],
    redirect: Comparisondzs4Z6XSdgMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/comparison/components/Comparison.vue").then(m => m.default || m)
  },
  {
    name: Comparisondzs4Z6XSdgMeta?.name ?? "ua_comparison",
    path: Comparisondzs4Z6XSdgMeta?.path ?? "/ua/comparison",
    meta: Comparisondzs4Z6XSdgMeta || {},
    alias: Comparisondzs4Z6XSdgMeta?.alias || [],
    redirect: Comparisondzs4Z6XSdgMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/comparison/components/Comparison.vue").then(m => m.default || m)
  },
  {
    name: indexvPvn3gXZUCMeta?.name ?? "ru_delivery",
    path: indexvPvn3gXZUCMeta?.path ?? "/delivery",
    meta: indexvPvn3gXZUCMeta || {},
    alias: indexvPvn3gXZUCMeta?.alias || [],
    redirect: indexvPvn3gXZUCMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/delivery/index.vue").then(m => m.default || m)
  },
  {
    name: indexvPvn3gXZUCMeta?.name ?? "ua_delivery",
    path: indexvPvn3gXZUCMeta?.path ?? "/ua/delivery",
    meta: indexvPvn3gXZUCMeta || {},
    alias: indexvPvn3gXZUCMeta?.alias || [],
    redirect: indexvPvn3gXZUCMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/delivery/index.vue").then(m => m.default || m)
  },
  {
    name: indexUzbQwBF1yxMeta?.name ?? "ru_search",
    path: indexUzbQwBF1yxMeta?.path ?? "/search",
    meta: indexUzbQwBF1yxMeta || {},
    alias: indexUzbQwBF1yxMeta?.alias || [],
    redirect: indexUzbQwBF1yxMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/products/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexUzbQwBF1yxMeta?.name ?? "ua_search",
    path: indexUzbQwBF1yxMeta?.path ?? "/ua/search",
    meta: indexUzbQwBF1yxMeta || {},
    alias: indexUzbQwBF1yxMeta?.alias || [],
    redirect: indexUzbQwBF1yxMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/products/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexohyOKhsw77Meta?.name ?? "ru_order",
    path: indexohyOKhsw77Meta?.path ?? "/order/:orderId",
    meta: indexohyOKhsw77Meta || {},
    alias: indexohyOKhsw77Meta?.alias || [],
    redirect: indexohyOKhsw77Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/order/components/index.vue").then(m => m.default || m)
  },
  {
    name: indexohyOKhsw77Meta?.name ?? "ua_order",
    path: indexohyOKhsw77Meta?.path ?? "/ua/order/:orderId",
    meta: indexohyOKhsw77Meta || {},
    alias: indexohyOKhsw77Meta?.alias || [],
    redirect: indexohyOKhsw77Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/order/components/index.vue").then(m => m.default || m)
  },
  {
    name: indexbzUlwcxTjCMeta?.name ?? "ru_solnechnyye-elektrostantsii-pod-klyuch",
    path: indexbzUlwcxTjCMeta?.path ?? "/solnechnyye-elektrostantsii-pod-klyuch",
    meta: indexbzUlwcxTjCMeta || {},
    alias: indexbzUlwcxTjCMeta?.alias || [],
    redirect: indexbzUlwcxTjCMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/landing/solarPowerPlant/index.vue").then(m => m.default || m)
  },
  {
    name: indexbzUlwcxTjCMeta?.name ?? "ua_solnechnyye-elektrostantsii-pod-klyuch",
    path: indexbzUlwcxTjCMeta?.path ?? "/ua/solnechnyye-elektrostantsii-pod-klyuch",
    meta: indexbzUlwcxTjCMeta || {},
    alias: indexbzUlwcxTjCMeta?.alias || [],
    redirect: indexbzUlwcxTjCMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/landing/solarPowerPlant/index.vue").then(m => m.default || m)
  },
  {
    name: indexoNLrLOrvSlMeta?.name ?? "ru_account",
    path: indexoNLrLOrvSlMeta?.path ?? "/account",
    meta: indexoNLrLOrvSlMeta || {},
    alias: indexoNLrLOrvSlMeta?.alias || [],
    redirect: indexoNLrLOrvSlMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/index.vue").then(m => m.default || m),
    children: [
  {
    name: ProfilePcI75Uc1qfMeta?.name ?? "ru_account_profile",
    path: ProfilePcI75Uc1qfMeta?.path ?? "profile",
    meta: ProfilePcI75Uc1qfMeta || {},
    alias: ProfilePcI75Uc1qfMeta?.alias || [],
    redirect: ProfilePcI75Uc1qfMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Profile.vue").then(m => m.default || m)
  },
  {
    name: OrdersGqgpmBuz1QMeta?.name ?? "ru_account_orders",
    path: OrdersGqgpmBuz1QMeta?.path ?? "orders",
    meta: OrdersGqgpmBuz1QMeta || {},
    alias: OrdersGqgpmBuz1QMeta?.alias || [],
    redirect: OrdersGqgpmBuz1QMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Orders.vue").then(m => m.default || m)
  },
  {
    name: FavoritesObqtuYUkVwMeta?.name ?? "ru_account_favorites",
    path: FavoritesObqtuYUkVwMeta?.path ?? "favorites",
    meta: FavoritesObqtuYUkVwMeta || {},
    alias: FavoritesObqtuYUkVwMeta?.alias || [],
    redirect: FavoritesObqtuYUkVwMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Favorites.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexoNLrLOrvSlMeta?.name ?? "ua_account",
    path: indexoNLrLOrvSlMeta?.path ?? "/ua/account",
    meta: indexoNLrLOrvSlMeta || {},
    alias: indexoNLrLOrvSlMeta?.alias || [],
    redirect: indexoNLrLOrvSlMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/index.vue").then(m => m.default || m),
    children: [
  {
    name: ProfilePcI75Uc1qfMeta?.name ?? "ua_account_profile",
    path: ProfilePcI75Uc1qfMeta?.path ?? "profile",
    meta: ProfilePcI75Uc1qfMeta || {},
    alias: ProfilePcI75Uc1qfMeta?.alias || [],
    redirect: ProfilePcI75Uc1qfMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Profile.vue").then(m => m.default || m)
  },
  {
    name: OrdersGqgpmBuz1QMeta?.name ?? "ua_account_orders",
    path: OrdersGqgpmBuz1QMeta?.path ?? "orders",
    meta: OrdersGqgpmBuz1QMeta || {},
    alias: OrdersGqgpmBuz1QMeta?.alias || [],
    redirect: OrdersGqgpmBuz1QMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Orders.vue").then(m => m.default || m)
  },
  {
    name: FavoritesObqtuYUkVwMeta?.name ?? "ua_account_favorites",
    path: FavoritesObqtuYUkVwMeta?.path ?? "favorites",
    meta: FavoritesObqtuYUkVwMeta || {},
    alias: FavoritesObqtuYUkVwMeta?.alias || [],
    redirect: FavoritesObqtuYUkVwMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Favorites.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index8ENwf69fp9Meta?.name ?? "ru_lookup-device",
    path: index8ENwf69fp9Meta?.path ?? "/lookup-device",
    meta: index8ENwf69fp9Meta || {},
    alias: index8ENwf69fp9Meta?.alias || [],
    redirect: index8ENwf69fp9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/lookupDevice/components/index.vue").then(m => m.default || m)
  },
  {
    name: index8ENwf69fp9Meta?.name ?? "ua_lookup-device",
    path: index8ENwf69fp9Meta?.path ?? "/ua/lookup-device",
    meta: index8ENwf69fp9Meta || {},
    alias: index8ENwf69fp9Meta?.alias || [],
    redirect: index8ENwf69fp9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/lookupDevice/components/index.vue").then(m => m.default || m)
  },
  {
    name: indexvBYfwU18aNMeta?.name ?? "ru_application",
    path: indexvBYfwU18aNMeta?.path ?? "/jk-bms",
    meta: indexvBYfwU18aNMeta || {},
    alias: indexvBYfwU18aNMeta?.alias || [],
    redirect: indexvBYfwU18aNMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/application/index.vue").then(m => m.default || m)
  },
  {
    name: indexvBYfwU18aNMeta?.name ?? "ua_application",
    path: indexvBYfwU18aNMeta?.path ?? "/ua/jk-bms",
    meta: indexvBYfwU18aNMeta || {},
    alias: indexvBYfwU18aNMeta?.alias || [],
    redirect: indexvBYfwU18aNMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/application/index.vue").then(m => m.default || m)
  },
  {
    name: indexWXP1WKu7iGMeta?.name ?? "ru_black-friday",
    path: indexWXP1WKu7iGMeta?.path ?? "/black-friday",
    meta: indexWXP1WKu7iGMeta || {},
    alias: indexWXP1WKu7iGMeta?.alias || [],
    redirect: indexWXP1WKu7iGMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/blackFriday/index.vue").then(m => m.default || m)
  },
  {
    name: indexWXP1WKu7iGMeta?.name ?? "ua_black-friday",
    path: indexWXP1WKu7iGMeta?.path ?? "/ua/black-friday",
    meta: indexWXP1WKu7iGMeta || {},
    alias: indexWXP1WKu7iGMeta?.alias || [],
    redirect: indexWXP1WKu7iGMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/blackFriday/index.vue").then(m => m.default || m)
  },
  {
    name: indexbmqbKMPIl0Meta?.name ?? "ru_lp-autonomic",
    path: indexbmqbKMPIl0Meta?.path ?? "/lp-autonomic-energiya-tvoyey-zhizni",
    meta: indexbmqbKMPIl0Meta || {},
    alias: indexbmqbKMPIl0Meta?.alias || [],
    redirect: indexbmqbKMPIl0Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/landing/autonomic/index.vue").then(m => m.default || m)
  },
  {
    name: indexbmqbKMPIl0Meta?.name ?? "ua_lp-autonomic",
    path: indexbmqbKMPIl0Meta?.path ?? "/ua/lp-autonomic-energiya-tvoyey-zhizni",
    meta: indexbmqbKMPIl0Meta || {},
    alias: indexbmqbKMPIl0Meta?.alias || [],
    redirect: indexbmqbKMPIl0Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/landing/autonomic/index.vue").then(m => m.default || m)
  },
  {
    name: indexhL90J2nn6yMeta?.name ?? "ru_installation-work",
    path: indexhL90J2nn6yMeta?.path ?? "/installation-work",
    meta: indexhL90J2nn6yMeta || {},
    alias: indexhL90J2nn6yMeta?.alias || [],
    redirect: indexhL90J2nn6yMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/installationWork/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexhL90J2nn6yMeta?.name ?? "ua_installation-work",
    path: indexhL90J2nn6yMeta?.path ?? "/ua/installation-work",
    meta: indexhL90J2nn6yMeta || {},
    alias: indexhL90J2nn6yMeta?.alias || [],
    redirect: indexhL90J2nn6yMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/installationWork/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexcPqg33AOicMeta?.name ?? "ru_installation-work_article",
    path: indexcPqg33AOicMeta?.path ?? "/installation-work/:installationArticleSlug",
    meta: indexcPqg33AOicMeta || {},
    alias: indexcPqg33AOicMeta?.alias || [],
    redirect: indexcPqg33AOicMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/installationWork/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: indexcPqg33AOicMeta?.name ?? "ua_installation-work_article",
    path: indexcPqg33AOicMeta?.path ?? "/ua/installation-work/:installationArticleSlug",
    meta: indexcPqg33AOicMeta || {},
    alias: indexcPqg33AOicMeta?.alias || [],
    redirect: indexcPqg33AOicMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/installationWork/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: indextJiflr0ffZMeta?.name ?? "ru_reviews-policy",
    path: indextJiflr0ffZMeta?.path ?? "/reviews-policy",
    meta: indextJiflr0ffZMeta || {},
    alias: indextJiflr0ffZMeta?.alias || [],
    redirect: indextJiflr0ffZMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/reviewsPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indextJiflr0ffZMeta?.name ?? "ua_reviews-policy",
    path: indextJiflr0ffZMeta?.path ?? "/ua/reviews-policy",
    meta: indextJiflr0ffZMeta || {},
    alias: indextJiflr0ffZMeta?.alias || [],
    redirect: indextJiflr0ffZMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/reviewsPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indexlhxQnMtihPMeta?.name ?? "ru_loyalty-policy",
    path: indexlhxQnMtihPMeta?.path ?? "/loyalty-policy",
    meta: indexlhxQnMtihPMeta || {},
    alias: indexlhxQnMtihPMeta?.alias || [],
    redirect: indexlhxQnMtihPMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/loyaltyPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indexlhxQnMtihPMeta?.name ?? "ua_loyalty-policy",
    path: indexlhxQnMtihPMeta?.path ?? "/ua/loyalty-policy",
    meta: indexlhxQnMtihPMeta || {},
    alias: indexlhxQnMtihPMeta?.alias || [],
    redirect: indexlhxQnMtihPMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/loyaltyPolicy/index.vue").then(m => m.default || m)
  }
]