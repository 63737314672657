<template>
  <div class="sign-in__w">
    <img class="sign-in__logo" src="/img/signIn-cat.svg" alt="sign in logo" />
    <form
      class="sign-in"
      :class="{ error: null !== passwordInvalid || null !== usernameInvalid }"
      @submit.prevent="onSignIn"
    >
      <InputText
        v-model="form.emailOrPhone"
        :validate="v.emailOrPhone"
        :caption="_T('@Email or phone')"
        :custom-error="usernameInvalid"
      />
      <Password
        v-model="form.password"
        :validate="v.password"
        :caption="_T('@Password')"
        :custom-error="passwordInvalid"
        :autocomplete-type="'current-password'"
      />
      <div class="sign-in__footer">
        <ButtonPrimary
          button-width="268px"
          type="submit"
          :disabled="signInHandler.isHandled"
        >
          {{ _T("@Log in") }}
        </ButtonPrimary>
        <div
          class="sign-in__footer-button"
          @click.prevent="changeComponent('signUp')"
        >
          {{ _T("@Registration") }}
        </div>
        <div
          class="sign-in__footer-button"
          @click.prevent="changeComponent('passwordReset')"
        >
          {{ _T("@Remind password") }}
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import Password from "~/modules/shared/inputs/Password.vue";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import InputText from "~/modules/shared/inputs/InputText.vue";
import { requiredValidate } from "~/utils/validators";
import { useUserStore } from "~/store/userStore";
import {
  accountModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { useCartStore } from "~/store/cartStore";
import { signFormStore } from "~/modules/account/store/accountFormStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import forbidden from "~/config/forbidden.config";
import { useAPI } from "~/uses/useMyFetch";

const emits = defineEmits(["changeComponent"]);

const formStore = signFormStore();
const userStore = useUserStore();
const modalStore = useModalStore();
const cartStore = useCartStore();

const passwordInvalid = ref(null);
const usernameInvalid = ref(null);

const form = reactive({
  emailOrPhone: "",
  password: "",
});

const rules = computed(() => ({
  emailOrPhone: {
    required: requiredValidate(),
  },
  password: {
    required: requiredValidate(),
  },
}));

const v = useVuelidate(rules, form);

function requestOptions() {
  return {
    username: form.emailOrPhone,
    password: form.password,
  };
}

const signInHandler = useSingletonHandler(() =>
  useAPI("/account/sign/in/credentials", {
    method: "POST",
    body: {
      ...formStore.formRequestOptions,
      cartProductsUnionPolicy: modalStore.getExtra(accountModal)?.isCheckout
        ? "replacement"
        : "merge",
    },
  }),
);

async function onSignIn() {
  if (formStore.isFormsReady()) {
    passwordInvalid.value = null;
    usernameInvalid.value = null;

    try {
      await signInHandler.handle();

      await userStore.fetch();
      await cartStore.fetch();

      document.documentElement.style.overflow = "auto";
      modalStore.toggleModal(accountModal);
    } catch (error) {
      if (forbidden.ACCOUNT_PASSWORD_INVALID === error.data.code) {
        passwordInvalid.value = _T("@Incorrect password");
      }

      if (forbidden.ACCOUNT_USERNAME_INVALID === error.data.code) {
        usernameInvalid.value = _T(
          "@The user with this data is not registered, please register",
        );
      }
    }
  }
}

function changeComponent(component) {
  emits("changeComponent", component);
}

onMounted(() => {
  formStore.addForm({ v, requestOptions, id: "SignIn" });
});

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.sign-in {
  width: 100%;

  @include flex-container(column, center, center);
  gap: 24px;

  &.error {
    animation: horizontal-shaking 0.2s ease-in-out 0s 2;
  }

  &__w {
    width: 100%;

    @include flex-container(row, center, center);
    gap: 24px;

    padding: 24px 16px;
  }

  &__logo {
    @include bigMobile {
      display: none;
    }
  }

  &__footer {
    width: 100%;

    @include flex-container(column, center, center);
    gap: 16px;
  }

  &__footer-button {
    @include font(16, 20);
    color: var(--color-primary-base);

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
