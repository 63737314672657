<template>
  <section class="widget-helper-mob" :class="{ active: activeMainWidget }">
    <TransitionGroup name="widget">
      <div
        v-if="!activeMainWidget"
        class="widget-helper-mob__main"
        @click="openMainWidget"
      >
        <div class="widget-helper-mob__main-item first-icon">
          <img
            src="~~/assets/icons/widget-helper-icon.svg"
            alt="widget helper"
          />
        </div>
        <div class="widget-helper-mob__main-item second-icon">
          <p class="widget-helper-mob__main-item-text">
            {{ _T("@Connect with us") }}
          </p>
        </div>
      </div>
    </TransitionGroup>
    <TransitionGroup name="widget-open">
      <div v-if="activeMainWidget" class="widget-helper-mob__close">
        <div class="widget-helper-mob__close-item" @click="closeMainWidget">
          <span></span>
        </div>
        <div class="widget-helper-mob__close-title">
          {{ _T("@Connect with us") }}
        </div>
        <div class="widget-helper-mob__content">
          <div
            v-for="(item, index) in allWidgets"
            :key="index"
            class="widget-helper-mob__item"
            @click="clickSelectedIcon(item.link)"
          >
            <div class="widget-helper-mob__item-picture">
              <img :src="`/img/social-media/${item.img}.svg`" :alt="item.img" />
            </div>
            <p class="widget-helper-mob__item-text">{{ _T(item.name) }}</p>
          </div>
          <div class="widget-helper-mob__item phone" @click="clickPhone">
            <div class="widget-helper-mob__item-picture">
              <img
                :src="`/img/social-media/${widgetPhone.img}.svg`"
                :alt="widgetPhone.img"
              />
            </div>
            <p class="widget-helper-mob__item-text">
              {{ _T(widgetPhone.name) }}
            </p>
          </div>
        </div>
      </div>
    </TransitionGroup>
  </section>
</template>

<script setup>
const widgetPhone = ref({
  img: "phone",
  name: "@Back call",
  active: false,
});

const allWidgets = ref([
  {
    img: "telegram",
    name: "Telegram",
    link: "https://t.me/LogicPowerBot",
    active: false,
  },
  {
    img: "viber",
    name: "Viber",
    link: "viber://pa?chatURI=lpofficial",
    active: false,
  },
  {
    img: "chat",
    name: "@Help desk widget",
    link: " https://t.me/sup_logicpower",
    active: false,
  },
  {
    img: "facebook",
    name: "Facebook",
    link: "https://www.facebook.com/logicpower.official/",
    active: false,
  },
  {
    img: "instagram",
    name: "Instagram",
    link: "https://www.instagram.com/logicpower_official/",
    active: false,
  },
]);

const activeMainWidget = ref(false);
function openMainWidget() {
  activeMainWidget.value = !activeMainWidget.value;
  document.documentElement.style.overflow = "hidden";
}

function closeMainWidget() {
  activeMainWidget.value = !activeMainWidget.value;
  document.documentElement.style.overflow = "auto";
}

function clickSelectedIcon(url) {
  window.open(url, "_blank");
  closeMainWidget();
}

function clickPhone() {
  ringostatAPI.openCallbackForm();
  closeMainWidget();
}
</script>

<style lang="scss" scoped>
.widget-helper-mob {
  position: fixed;
  z-index: 1000;
  bottom: 80px;
  right: 35px;

  width: 54px;
  height: 54px;
  transition: all 0.5s ease-in-out;

  &.active {
    position: fixed;
    z-index: 1500;
    bottom: 0;
    right: 0;

    width: 100%;
    height: 100%;
    background-color: white;
  }

  &__main {
    cursor: pointer;
    position: absolute;
    z-index: 4;
    width: 54px;
    height: 54px;
    background-color: var(--color-primary-base);
    border-radius: 50%;
    overflow: hidden;
    animation: shadow 2.5s ease-in-out infinite 0.8s;
    transition: background-color 0.4s ease-in-out;

    &:hover {
      background-color: var(--color-primary-dark);
    }
  }

  &__main-item {
    width: 54px;
    height: 54px;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;

    @include flex-container(column, center, center);
    transition: all 0.4s ease-in-out;

    &.first-icon {
      transform: translateX(0);
      animation: firstStep 5s infinite linear 0.8s;

      & img {
        width: 28px;
        height: 28px;
      }
    }

    &.second-icon {
      transform: translateX(100%);
      animation: secondStep 5s infinite linear 0.8s;
    }
  }

  &__main-item-text {
    padding: 2px 1px;
    text-align: center;
    @include font(9, 10, 600);
    color: white;
  }

  &__close {
    width: 100%;
    height: 100%;
    padding: 40px 16px;
    @include flex-container(column, flex-start);
    gap: 21px;
  }

  &__close-item {
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 16px;
    width: 24px;
    height: 24px;

    transition: all 0.4s ease-in-out;

    & span {
      position: absolute;
      z-index: 4;
      top: 10px;
      left: 2px;

      width: 20px;
      height: 2px;
      background-color: black;
      transform: rotate(45deg);
      transition: all 0.4s ease-in-out;

      &::after {
        content: "";
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;

        width: 20px;
        height: 2px;
        background-color: black;
        transform: rotate(90deg);
        transition: all 0.4s ease-in-out;
      }
    }
    &:hover span,
    &:hover span::after {
      background-color: var(--color-red-dark);
    }
  }

  &__close-title {
    @include font(18, 25, 500);
  }

  &__content {
    position: relative;
    @include flex-container(column, flex-start);
  }

  &__item {
    cursor: pointer;
    padding: 8px;
    @include flex-container(row, flex-start, center);
    gap: 8px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #d1d1d1;
    }

    &:hover {
      & div {
        animation: hoverIcon 1.5s linear infinite;
      }

      & p {
        color: var(--color-primary-dark);
      }
    }
  }

  &__item-picture {
    position: relative;
    width: 40px;
    height: 40px;

    background-color: var(--color-primary-base);
    border-radius: 50%;

    @include flex-container(row, center, center);
  }

  &__item-text {
    color: black;
    @include font(16, 19, 500);
    letter-spacing: -0.32px;
    transition: all 0.4s ease-in-out;
  }
}

.widget-leave-active,
.widget-enter-active {
  transition: all 0.4s linear;
}

.widget-enter-from {
  opacity: 0;
  transform: rotate(0) scale(0.5);
}

.widget-enter-to {
  opacity: 1;
  transition: all 0.4s linear;
  transform: rotate(1440deg) scale(1);
}

.widget-leave-to {
  transition: all 0.4s linear;
  transform: rotate(1440deg) scale(0.5);
  opacity: 0;
}

.widget-open-leave-active,
.widget-open-enter-active {
  transition: all 0.6s linear 0.3s;
}

.widget-open-enter-from {
  opacity: 0;
  transform: translateY(-100%);
}

.widget-open-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.widget-open-leave-to {
  transition: all 0.4s linear;
  opacity: 0;
}

@keyframes firstStep {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-120%);
    opacity: 0;
  }
  40% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    opacity: 1;
    transform: translateX(100%);
  }
  70% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes secondStep {
  0% {
    opacity: 1;
    transform: translateX(100%);
  }
  20% {
    opacity: 1;
    transform: translateX(0%);
  }
  50% {
    opacity: 1;
    transform: translateX(0%);
  }
  80% {
    opacity: 0;
    transform: translateX(-120%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes shadow {
  0% {
    box-shadow: 0 0 2px rgba(242, 106, 33);
  }

  50% {
    box-shadow:
      0 0 1px 8px rgba(242, 106, 33, 0.4),
      0 0 1px 18px rgba(242, 106, 33, 0.3),
      0 0 1px 36px rgba(242, 106, 33, 0.15);
  }
  100% {
    box-shadow: 0 0 2px rgba(242, 106, 33);
  }
}

@keyframes hoverIcon {
  0% {
    box-shadow: 0 0 2px rgba(242, 106, 33);
  }

  50% {
    box-shadow:
      0 0 1px 2px rgba(242, 106, 33, 0.4),
      0 0 1px 6px rgba(242, 106, 33, 0.3),
      0 0 1px 12px rgba(242, 106, 33, 0.15);
    background-color: var(--color-primary-dark);
  }
  100% {
    box-shadow: 0 0 2px rgba(242, 106, 33);
  }
}
</style>
