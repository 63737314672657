<template>
  <div class="modal" @click.stop="closeModal">
    <div class="modal__wrapper" @click.stop>
      <div class="success">
        <div class="success__header">
          <p class="success__modal-name">
            {{ successfulData.title }}
          </p>
          <button class="success__close" @click.stop.prevent="closeModal">
            <img src="~~/assets/icons/cross.svg" alt="close button" />
          </button>
        </div>
        <div class="success__body">
          <div class="success__body-img-w">
            <img src="/img/successful-form.svg" alt="successful form" />
          </div>
          <p class="success__body-text">{{ successfulData.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  successfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";

const emits = defineEmits(["close"]);

const modalStore = useModalStore();
const successfulData = storeToRefs(modalStore).getExtra.value(successfulModal);

function closeModal() {
  emits("close");
  document.documentElement.style.overflow = "auto";
}

onMounted(() => {
  document.documentElement.style.overflow = "hidden";

  if (successfulData.isWithTimeout) {
    setTimeout(() => {
      closeModal();
    }, 5000);
  }
});
</script>

<style lang="scss" scoped>
.success {
  background-color: white;
  border-radius: 8px;

  &__header {
    @include flex-container(row, space-between, center);

    border-bottom: 1px solid #d1d1d1;

    padding: 16px;
    gap: 16px;
  }

  &__modal-name {
    @include font(20, 28);
    letter-spacing: 0.02em;
    color: #2b2b2b;
  }

  &__close {
    @include fixedHW(32px, 32px);

    @include flex-container(row, center, center);

    & img {
      @include fixedHW(14px, 14px);
    }
  }

  &__body {
    @include flex-container(column, center, center);

    padding: 24px 16px;
    gap: 24px;
  }

  &__body-img-w {
    width: 84px;
    height: 84px;

    @include flex-container(row, center, center);

    border: 2px solid var(--color-primary-base);
    border-radius: 50%;
  }

  &__body-text {
    @include font(18, 24, 500);
    text-align: center;
  }
}
</style>
