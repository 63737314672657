<template>
  <div
    ref="refSubscriptionModal"
    class="modal"
    tabindex="0"
    @keydown.esc="closeModal"
  >
    <div v-on-click-outside="closeModal" class="modal__wrapper logic-fox">
      <div class="subscription">
        <div class="subscription__header">
          <button class="subscription__close" @click.stop.prevent="closeModal">
            <img src="~~/assets/icons/cross-white.svg" alt="close button" />
          </button>
        </div>
        <div class="subscription__body">
          <div class="subscription__content">
            <span class="subscription__line"></span>
            <h3 class="subscription__title">{{ _T(autonomicData.title) }}</h3>

            <div class="subscription__container">
              <p
                v-for="(description, index) in autonomicData.description"
                :key="index"
                class="subscription__description"
              >
                {{ _T(description) }}
              </p>
            </div>
          </div>
          <form action="" class="subscription__form">
            <div class="subscription__inputs">
              <InputText
                v-model="form.firstName"
                :validate="v.firstName"
                :maxlength="255"
                :caption="_T('@Name')"
                :autocomplete-type="'given-name'"
                :logic-fox="true"
              />
              <InputPhone
                v-model="form.phone"
                :validate="v.phone"
                :caption="_T('@Phone number')"
                :logic-fox="true"
              />
              <InputMail
                v-model="form.email"
                :validate="v.email"
                :caption="'E-mail'"
                :logic-fox="true"
              />
            </div>

            <div class="subscription__checkbox-wrp">
              <label
                class="subscription__agree"
                :class="{ error: v.isAgree.$invalid && v.isAgree.$dirty }"
              >
                <input v-model="form.isAgree" type="checkbox" />
                <span class="subscription__caption">
                  <span
                    >{{ _T("@I agree with") }}
                    <MyLink
                      class="subscription__caption-link"
                      :name="'privacy-policy'"
                    >
                      {{ _T("@privacy policy") }}
                    </MyLink>
                  </span>
                </span>
              </label>
              <button
                class="subscription__button"
                :disabled="subscribeHandler.isHandled"
                @click.prevent="onSubscribe"
              >
                <span>{{ _T("@Send") }}</span>
                <img src="/img/arrow_right_light.svg" alt="arrow" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputText from "~~/modules/shared/inputs/InputText.vue";
import InputMail from "~/modules/shared/inputs/InputMail.vue";
import InputPhone from "~/modules/shared/inputs/InputPhone.vue";
import {
  successfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  requiredValidate,
  sameAsValidate,
  phoneValidate,
} from "~/utils/validators";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { vOnClickOutside } from "@vueuse/components";
import { useAPI } from "~/uses/useMyFetch";

const emits = defineEmits(["close"]);

const modalStore = useModalStore();
const formStore = modalFormStore();
const userStore = useUserStore();

const { currentUser: user } = storeToRefs(userStore);

const refSubscriptionModal = ref(null);
const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const autonomicData = {
  title: "@Want to know more about Autonomic's capabilities",
  description: [
    "@Get a free consultation",
    "@Our manager will contact you and answer all questions.",
  ],
};

const form = reactive({
  firstName: "",
  phone: "",
  email: "",
  isAgree: true,
});

const rules = computed(() => ({
  firstName: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
  },
  phone: {
    required: requiredValidate(),
    phone: phoneValidate(),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
  },
  isAgree: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

function closeModal() {
  emits("close");
  document.documentElement.style.overflow = "auto";
}

const subscribeHandler = useSingletonHandler(() =>
  useAPI("/marketing/event", {
    method: "POST",
    body: {
      event: "autonomicLandingRequestCreated",
      email: form.email,
      isConfirmed: true,
      name: form.firstName,
      phone: form.phone,
    },
  }).then(() => {
    closeModal();

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

async function onSubscribe() {
  if (formStore.isFormsReady()) {
    await subscribeHandler.handle();
  }
}

onMounted(() => {
  document.documentElement.style.overflow = "hidden";
  refSubscriptionModal.value.focus();

  if (null !== user.value) {
    form.firstName = user.value.name.firstName;
    form.email = user.value.email;
  }

  formStore.addForm({ v, id: "subscription" });
});

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.subscription {
  background: #222429;
  border-radius: 16px;
  color: #fff;

  @include mobile {
    height: 100%;
    border-radius: 0;
  }

  &__header {
    @include flex-container(row, flex-end, center);

    padding: 8px 16px;
  }

  &__close {
    @include fixedHW(32px, 32px);

    @include flex-container(row, center, center);

    & img {
      @include fixedHW(32px, 32px);
    }
  }

  &__body {
    @include flex-container(row, space-between, center);

    padding: 0 48px 48px;
    gap: 57px;

    @include mobile {
      @include flex-container(column, center, center);
      padding: 22px 16px 29px;
    }
  }

  &__content {
    max-width: 390px;
    width: 100%;

    @include flex-container(column);
    gap: 16px;

    @include mobile {
      align-items: center;
      text-align: center;
    }
  }

  &__title {
    font-family: "JWH V1", sans-serif;
    @include font(20, 24, 400);
    letter-spacing: 0.2px;

    @include mobile {
      @include font(18, 22, 400);
      letter-spacing: 0.18px;
    }
  }

  &__description {
    max-width: 360px;
    @include font(16, 23, 400);
    letter-spacing: 0.32px;
    color: #e9e9e9;

    @include mobile {
      @include font(14, 20, 400);
      letter-spacing: 0.28px;
    }
  }

  &__line {
    width: 80px;

    border: 1px solid var(--color-primary-base);

    @include bigMobile {
      width: 60px;
    }
  }

  &__form {
    width: 100%;
    @include flex-container(column, space-between);

    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.5);

    background: linear-gradient(
      117deg,
      rgba(243, 243, 243, 0.3) 24.93%,
      rgba(243, 243, 243, 0.2) 101.84%
    );
    box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.05);

    gap: 24px;
    padding: 16px 24px;

    @include mobile {
      max-width: max-content;

      padding: 16px;
    }
  }

  &__inputs {
    @include flex-container(column, space-between, center);

    gap: 24px;
  }

  &__checkbox-wrp {
    width: min(100%, 363px);
    align-self: center;

    @include flex-container(column, center, center);
    gap: 24px;
  }

  &__agree {
    position: relative;

    align-self: flex-start;

    & input {
      height: 0;
      width: 0;

      position: absolute;
      left: 0;
      top: 50%;
      z-index: -1;

      opacity: 0;
      visibility: hidden;
    }

    & > span {
      @include flex-container(row, space-between, center);
      gap: 16px;

      user-select: none;
    }

    & > span::before {
      content: "";
      display: inline-block;

      width: 24px;
      height: 24px;

      flex: 0 0 24px;

      border: 1px solid #cdcfd0;
      border-radius: 4px;

      background-repeat: no-repeat;
      background-position: center;

      transition: 0.2s ease;
    }

    &.error > span::before {
      border-color: #f73b2f;
    }

    & input:not(:checked) + span:hover::before {
      border-color: var(--color-primary-base);
    }

    & input:checked + span::before {
      border-color: var(--color-primary-base);
      background-color: var(--color-primary-base);
      background-image: url("~~/assets/icons/check-icon.svg");
    }
  }

  &__caption {
    @include font(12, 16);
    color: #2b2b2b;

    padding: 0 8px;
  }

  &__caption-link {
    color: #fff;
  }

  &__button {
    cursor: pointer;
    width: 100%;

    @include flex-container(row, center, center);
    gap: 16px;

    border-radius: 30px;
    background-color: var(--color-primary-base);

    padding: 8px 16px 8px 24px;

    & span {
      @include font(20, 28, 400);
      color: #fff;

      transition: color 0.3s ease-in-out;
    }

    &:hover {
      background-color: var(--color-primary-light);
    }

    &:disabled {
      cursor: auto;

      background-color: #8a8a8a;
    }
  }
}
</style>

<style lang="scss">
.modal__wrapper {
  &.logic-fox {
    max-width: 955px;

    background-color: transparent;
    border-radius: 16px;
  }
}
</style>
